//@flow

import "./src/styles/index.scss";

import { MuiThemeProvider } from "@material-ui/core";
import React from "react";
import theme from "./src/styles/theme";

export const wrapRootElement = ({ element }: *) => (
    <MuiThemeProvider theme={theme}>{element}</MuiThemeProvider>
);
