// @flow

import { createMuiTheme } from "@material-ui/core";
import variables from "./_variables.scss";

export default createMuiTheme({
    // colour palette
    palette: {
        primary: {
            main: variables.primary,
        },
    },

    // typography
    typography: {
        fontFamily: ["Roboto", "Helvetica", "sans-serif"],
    },

    // custom styling
    overrides: {
        MuiButton: {
            root: {
                borderRadius: 0,
                padding: "1em 4em",
                letterSpacing: ".2em",
            },
        },
    },
});
